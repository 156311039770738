import React from 'react';
import { Heading } from 'reaxl';

const NoResult = ({ isKbb, handleClearSelectionsClick }) => (
    <div className="margin-vertical-3 margin-vertical-md-4">
        <Heading>
            No results found
        </Heading>
        <div className="text-size-400">
            Remove some criteria from your search
            {' '}
            {!isKbb && ' or'}
            {!isKbb && (
                <span
                    className="margin-left-2 text-link link-undecorated"
                    onClick={handleClearSelectionsClick}
                    role="presentation"
                >
                    start a new search
                </span>
            )}
        </div>
    </div>
);

export default NoResult;
